import React, { useState } from 'react';
import { PlaySquare, Star, Shield, Sword, Lock, AlertTriangle } from 'lucide-react';
import { useBadgeStore } from '../store/badgeStore';

interface Card {
  id: string;
  name: string;
  description: string;
  rarity: 'common' | 'rare' | 'legendary' | 'mythic';
  type: 'person' | 'event' | 'technology' | 'artifact';
  power: number;
  defense: number;
  year: number;
  image?: string;
  unlockRequirement: string;
  unlockHint: string;
}

const CryptoTCG: React.FC = () => {
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const { unlockedBadges } = useBadgeStore();

  const cards: Card[] = [
    {
      id: 'satoshi',
      name: 'Satoshi Nakamoto',
      description: 'The mysterious creator of Bitcoin',
      rarity: 'mythic',
      type: 'person',
      power: 9,
      defense: 9,
      year: 2008,
      image: 'https://images.unsplash.com/photo-1621416894569-0f39ed31d247?auto=format&fit=crop&w=300&q=80',
      unlockRequirement: 'challenge_satoshi_puzzle',
      unlockHint: 'Complete the Satoshi\'s First Message challenge'
    },
    {
      id: 'genesis_block',
      name: 'Genesis Block',
      description: 'The first block of the Bitcoin blockchain',
      rarity: 'legendary',
      type: 'event',
      power: 8,
      defense: 10,
      year: 2009,
      unlockRequirement: 'challenge_genesis_puzzle',
      unlockHint: 'Solve the Genesis Block Secret challenge'
    },
    {
      id: 'mt_gox_hack',
      name: 'Mt. Gox Hack',
      description: 'The infamous Bitcoin exchange hack',
      rarity: 'rare',
      type: 'event',
      power: 7,
      defense: 3,
      year: 2014,
      unlockRequirement: 'challenge_hash_challenge',
      unlockHint: 'Complete the Hash Function Basics challenge'
    },
    {
      id: 'hal_finney',
      name: 'Hal Finney',
      description: 'First Bitcoin transaction recipient',
      rarity: 'legendary',
      type: 'person',
      power: 8,
      defense: 8,
      year: 2009,
      image: 'https://images.unsplash.com/photo-1618044733300-9472054094ee?auto=format&fit=crop&w=300&q=80',
      unlockRequirement: 'challenge_binary_challenge',
      unlockHint: 'Decode the Binary Message challenge'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2 mb-6">
        <PlaySquare className="text-green-400" size={24} />
        <h2 className="text-2xl font-bold terminal-text">Crypto TCG</h2>
      </div>

      <div className="terminal-window p-4 rounded-lg mb-6">
        <div className="flex items-center gap-2 mb-2">
          <AlertTriangle size={16} className="text-yellow-500" />
          <h3 className="font-bold terminal-text">Card Collection Locked</h3>
        </div>
        <p className="text-sm terminal-text">
          Complete challenges to unlock cards. Each card requires specific achievements.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {cards.map((card) => {
          const isUnlocked = unlockedBadges.includes(card.unlockRequirement);
          
          return (
            <div
              key={card.id}
              className={`
                terminal-window p-4 rounded-lg border-2 transition-all card-reveal
                ${isUnlocked ? 
                  card.rarity === 'mythic' ? 'border-orange-500/50 shadow-[0_0_10px_rgba(255,165,0,0.2)]' :
                  card.rarity === 'legendary' ? 'border-purple-500/50 shadow-[0_0_10px_rgba(147,51,234,0.2)]' :
                  card.rarity === 'rare' ? 'border-blue-500/50 shadow-[0_0_10px_rgba(59,130,246,0.2)]' :
                  'border-green-500/50 shadow-[0_0_10px_rgba(0,255,0,0.2)]'
                : 'border-gray-500/30 opacity-50'}
                ${isUnlocked ? 'hover:bg-green-500/5 cursor-pointer' : 'cursor-not-allowed'}
              `}
              onClick={() => isUnlocked && setSelectedCard(card)}
            >
              <div className="relative">
                {!isUnlocked && (
                  <div className="absolute inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-10 rounded-lg">
                    <div className="text-center p-4">
                      <Lock size={24} className="mx-auto mb-2" />
                      <p className="text-sm terminal-text">{card.unlockHint}</p>
                    </div>
                  </div>
                )}

                {card.image && (
                  <div 
                    className="h-40 rounded-lg mb-4 bg-cover bg-center"
                    style={{ backgroundImage: `url(${card.image})` }}
                  />
                )}
                
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-lg font-bold terminal-text">{card.name}</h3>
                  <span className={`
                    text-sm px-2 py-1 rounded-full
                    ${card.rarity === 'mythic' ? 'bg-orange-500/20' :
                      card.rarity === 'legendary' ? 'bg-purple-500/20' :
                      card.rarity === 'rare' ? 'bg-blue-500/20' :
                      'bg-green-500/20'}
                  `}>
                    {card.rarity}
                  </span>
                </div>

                <p className="text-sm mb-4 terminal-text">{card.description}</p>

                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div className="flex items-center gap-1">
                    <Sword size={14} />
                    <span className="terminal-text">Power: {card.power}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Shield size={14} />
                    <span className="terminal-text">Defense: {card.defense}</span>
                  </div>
                </div>

                <div className="mt-2 flex items-center gap-1 text-sm opacity-70">
                  <Star size={14} />
                  <span className="terminal-text">Year: {card.year}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {selectedCard && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4">
          <div className="terminal-window w-full max-w-2xl p-6 rounded-lg relative">
            <button
              onClick={() => setSelectedCard(null)}
              className="absolute top-4 right-4 retro-button p-2 rounded hover:bg-green-500/10"
            >
              ×
            </button>

            <div className="flex flex-col md:flex-row gap-6">
              {selectedCard.image && (
                <div 
                  className="w-full md:w-1/2 h-64 rounded-lg bg-cover bg-center"
                  style={{ backgroundImage: `url(${selectedCard.image})` }}
                />
              )}
              
              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-2 terminal-text">
                  {selectedCard.name}
                </h2>
                <p className="mb-4 terminal-text">{selectedCard.description}</p>
                
                <div className="grid grid-cols-2 gap-4">
                  <div className="terminal-window p-3 rounded">
                    <div className="flex items-center gap-2">
                      <Sword size={16} />
                      <span className="terminal-text">Power: {selectedCard.power}</span>
                    </div>
                  </div>
                  <div className="terminal-window p-3 rounded">
                    <div className="flex items-center gap-2">
                      <Shield size={16} />
                      <span className="terminal-text">Defense: {selectedCard.defense}</span>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex items-center gap-2">
                  <Star size={16} />
                  <span className="terminal-text">Released: {selectedCard.year}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CryptoTCG;