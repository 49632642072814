import React from 'react';
import { Twitter } from 'lucide-react';

const Footer: React.FC = () => {
  return (
    <footer className="border-t border-green-500/30 mt-8 py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="text-sm terminal-text opacity-70">
            © 2024 Running Hal AI - All rights reserved
          </div>
          
          <a
            href="https://twitter.com/running_hal_ai"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm hover:text-green-400 transition-colors terminal-text"
          >
            <Twitter size={16} />
            @running_hal_ai
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;