import React, { useState, useEffect } from 'react';
import { Building2, Heart } from 'lucide-react';
import { glitchAnimation } from '../utils/animations';

const Museum = () => {
  const [glitchedHal, setGlitchedHal] = useState('');
  const [glitchedBtc, setGlitchedBtc] = useState('');
  const [isGlitching, setIsGlitching] = useState(false);

  const asciiHal = `
  ██╗  ██╗ █████╗ ██╗     
  ██║  ██║██╔══██╗██║     
  ███████║███████║██║     
  ██╔══██║██╔══██║██║     
  ██║  ██║██║  ██║███████╗
  ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝`;

  const asciiBTC = `
  ██████╗ ████████╗ ██████╗
  ██╔══██╗╚══██╔══╝██╔════╝
  ██████╔╝   ██║   ██║     
  ██╔══██╗   ██║   ██║     
  ██████╔╝   ██║   ╚██████╗
  ╚═════╝    ╚═╝    ╚═════╝`;

  useEffect(() => {
    let glitchInterval: NodeJS.Timeout;
    let resetTimeout: NodeJS.Timeout;

    const startGlitching = () => {
      setIsGlitching(true);
      glitchInterval = setInterval(() => {
        setGlitchedHal(glitchAnimation(asciiHal));
        setGlitchedBtc(glitchAnimation(asciiBTC));
      }, 100);

      resetTimeout = setTimeout(() => {
        clearInterval(glitchInterval);
        setIsGlitching(false);
        setGlitchedHal(asciiHal);
        setGlitchedBtc(asciiBTC);
      }, 2000);
    };

    // Initial values
    setGlitchedHal(asciiHal);
    setGlitchedBtc(asciiBTC);

    // Start glitch effect every 10 seconds
    const mainInterval = setInterval(startGlitching, 10000);

    return () => {
      clearInterval(mainInterval);
      clearInterval(glitchInterval);
      clearTimeout(resetTimeout);
    };
  }, []);

  const exhibits = [
    {
      title: "First Bitcoin Transaction",
      description: "January 12, 2009 - Satoshi sends 10 BTC to Hal",
      artifact: "TX: f4184fc596403b9d638783cf57adfe4c75c605f6356fbc91338530e9831e9e16"
    },
    {
      title: "Running Bitcoin",
      description: "January 11, 2009 - First tweet about Bitcoin",
      artifact: "Tweet: Running bitcoin"
    },
    {
      title: "PGP Development",
      description: "1991 - Major contributions to PGP 2.0",
      artifact: "PGP Key: 1024D/04D6A072"
    },
    {
      title: "RPOW System",
      description: "2004 - Reusable Proof of Work System",
      artifact: "http://rpow.net (Historical)"
    }
  ];

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-2 mb-6">
        <Building2 size={24} className="text-green-400" />
        <h2 className="text-2xl font-bold terminal-text">Digital Museum</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div 
          className={`terminal-window p-4 rounded-lg ${isGlitching ? 'glitch-effect' : ''}`}
          onMouseEnter={() => setIsGlitching(true)}
          onMouseLeave={() => setIsGlitching(false)}
        >
          <pre className="text-green-400 font-mono text-sm whitespace-pre matrix-animation">
            {glitchedHal}
          </pre>
          <div className="scanline"></div>
        </div>

        <div 
          className={`terminal-window p-4 rounded-lg ${isGlitching ? 'glitch-effect' : ''}`}
          onMouseEnter={() => setIsGlitching(true)}
          onMouseLeave={() => setIsGlitching(false)}
        >
          <pre className="text-green-400 font-mono text-sm whitespace-pre matrix-animation">
            {glitchedBtc}
          </pre>
          <div className="scanline"></div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {exhibits.map((exhibit, index) => (
          <div
            key={index}
            className="terminal-window p-4 rounded-lg border border-green-500/30 hover:bg-green-500/5 transition-all exhibit-reveal"
          >
            <h3 className="text-lg font-bold mb-2 terminal-text">{exhibit.title}</h3>
            <p className="text-sm mb-2 terminal-text">{exhibit.description}</p>
            <code className="block bg-black/30 p-2 rounded text-sm terminal-text">
              {exhibit.artifact}
            </code>
          </div>
        ))}
      </div>

      <div className="terminal-window p-6 rounded-lg">
        <div className="flex items-center gap-2 mb-4">
          <Heart size={24} className="text-red-500 pulse-animation" />
          <h3 className="text-xl font-bold terminal-text">ALS Awareness</h3>
        </div>
        <p className="mb-4 terminal-text">
          Hal Finney was diagnosed with ALS in 2009. Despite this challenge,
          he continued to contribute to Bitcoin and cryptography, showing
          remarkable resilience and determination.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <a
            href="https://www.als.org/donate"
            target="_blank"
            rel="noopener noreferrer"
            className="retro-button p-4 rounded text-center terminal-text hover-glow"
          >
            Support ALS Research
          </a>
          <a
            href="https://www.als.org"
            target="_blank"
            rel="noopener noreferrer"
            className="retro-button p-4 rounded text-center terminal-text hover-glow"
          >
            Learn More About ALS
          </a>
        </div>
      </div>
    </div>
  );
};

export default Museum;