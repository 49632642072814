export interface Challenge {
  id: string;
  title: string;
  description: string;
  difficulty: 'Easy' | 'Medium' | 'Hard' | 'Expert';
  category: 'Cryptography' | 'Bitcoin' | 'Programming' | 'History';
  reward: string;
  hint?: string;
  validator: (input: string) => Promise<boolean> | boolean;
}

export interface ChallengeCategory {
  name: string;
  description: string;
  icon: string;
}

export const CHALLENGE_CATEGORIES: Record<string, ChallengeCategory> = {
  Cryptography: {
    name: 'Cryptography',
    description: 'Test your knowledge of cryptographic principles and algorithms',
    icon: '🔐'
  },
  Bitcoin: {
    name: 'Bitcoin',
    description: 'Explore the technical aspects of Bitcoin and blockchain',
    icon: '₿'
  },
  Programming: {
    name: 'Programming',
    description: 'Solve coding challenges and implement key algorithms',
    icon: '💻'
  },
  History: {
    name: 'History',
    description: 'Discover the rich history of cryptocurrency and cypherpunks',
    icon: '📚'
  }
};