import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Badge {
  id: string;
  name: string;
  description: string;
  icon: string;
  unlockedAt?: number;
  secret?: boolean;
}

interface BadgeStore {
  unlockedBadges: string[];
  badges: Record<string, Badge>;
  lastUnlockedBadge: Badge | null;
  hasBadge: (id: string) => boolean;
  unlockBadge: (id: string) => void;
  clearLastUnlockedBadge: () => void;
}

export const useBadgeStore = create<BadgeStore>()(
  persist(
    (set, get) => ({
      unlockedBadges: [],
      lastUnlockedBadge: null,
      badges: {
        // Visitor Badges
        first_visit: {
          id: 'first_visit',
          name: 'Digital Pioneer',
          description: 'First time visiting Hal\'s memorial',
          icon: '🌟'
        },
        early_visitor: {
          id: 'early_visitor',
          name: 'Early Adopter',
          description: 'Visited within the first month of launch',
          icon: '🎯'
        },
        night_owl: {
          id: 'night_owl',
          name: 'Night Owl',
          description: 'Visited between 2 AM and 5 AM',
          icon: '🦉'
        },

        // Interaction Badges
        chat_started: {
          id: 'chat_started',
          name: 'Conversation Starter',
          description: 'Started a conversation with Hal\'s AI',
          icon: '💬'
        },
        deep_conversation: {
          id: 'deep_conversation',
          name: 'Deep Thinker',
          description: 'Had a 10-message conversation about cryptography',
          icon: '🤔'
        },
        archive_explorer: {
          id: 'archive_explorer',
          name: 'Archive Explorer',
          description: 'Viewed the Satoshi email archives',
          icon: '📚'
        },
        history_buff: {
          id: 'history_buff',
          name: 'History Buff',
          description: 'Spent over 30 minutes in the archives',
          icon: '📜'
        },

        // Challenge Badges
        challenge_master: {
          id: 'challenge_master',
          name: 'Cryptography Master',
          description: 'Completed all cryptographic challenges',
          icon: '🏆'
        },
        quick_solver: {
          id: 'quick_solver',
          name: 'Quick Thinker',
          description: 'Solved a challenge in under 60 seconds',
          icon: '⚡'
        },
        persistence: {
          id: 'persistence',
          name: 'Persistent Scholar',
          description: 'Attempted a challenge more than 10 times before solving',
          icon: '🔨'
        },

        // Game Badges
        miles_runner: {
          id: 'miles_runner',
          name: 'Digital Runner',
          description: 'Played Miles with Hal',
          icon: '🏃'
        },
        miles_master: {
          id: 'miles_master',
          name: 'Marathon Runner',
          description: 'Scored over 1000 in Miles with Hal',
          icon: '🏅'
        },
        speed_demon: {
          id: 'speed_demon',
          name: 'Speed Demon',
          description: 'Completed a perfect run without hitting obstacles',
          icon: '💨'
        },

        // Secret Badges
        console_explorer: {
          id: 'console_explorer',
          name: 'Console Explorer',
          description: 'Found hidden messages in the browser console',
          icon: '🔍',
          secret: true
        },
        code_archaeologist: {
          id: 'code_archaeologist',
          name: 'Code Archaeologist',
          description: 'Discovered ancient comments in the source',
          icon: '⛏️',
          secret: true
        },
        satoshi_secret: {
          id: 'satoshi_secret',
          name: 'Satoshi\'s Secret',
          description: 'Found the hidden message in the Genesis Block',
          icon: '🤫',
          secret: true
        },

        // Special Event Badges
        bitcoin_pizza: {
          id: 'bitcoin_pizza',
          name: 'Pizza Day',
          description: 'Visited on Bitcoin Pizza Day (May 22)',
          icon: '🍕'
        },
        genesis_day: {
          id: 'genesis_day',
          name: 'Genesis Block',
          description: 'Visited on Genesis Block Day (January 3)',
          icon: '📅'
        },
        hal_birthday: {
          id: 'hal_birthday',
          name: 'Birthday Tribute',
          description: 'Visited on Hal\'s Birthday',
          icon: '🎂'
        }
      },
      hasBadge: (id: string) => get().unlockedBadges.includes(id),
      unlockBadge: (id: string) => {
        const { unlockedBadges, badges } = get();
        if (!unlockedBadges.includes(id) && badges[id]) {
          const badge = {
            ...badges[id],
            unlockedAt: Date.now()
          };
          set({
            unlockedBadges: [...unlockedBadges, id],
            badges: {
              ...badges,
              [id]: badge
            },
            lastUnlockedBadge: badge
          });
        }
      },
      clearLastUnlockedBadge: () => set({ lastUnlockedBadge: null })
    }),
    {
      name: 'hal-finney-badges'
    }
  )
);