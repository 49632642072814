import React, { useState, useRef, useEffect } from 'react';
import { MessageSquare, Send } from 'lucide-react';
import { chatService, ChatMessage } from '../services/api';
import { useBadgeStore } from '../store/badgeStore';

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      role: 'assistant',
      content: "Hello! I'm Hal Finney's digital consciousness. I'd be happy to discuss cryptography, Bitcoin, running, or any other topics you're interested in.",
    },
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { unlockBadge } = useBadgeStore();
  const [streamingContent, setStreamingContent] = useState<string>('');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const newMessage: ChatMessage = { role: 'user', content: input };
    setMessages(prev => [...prev, newMessage]);
    setInput('');
    setIsLoading(true);
    setError(null);
    setStreamingContent('');

    try {
      setMessages(prev => [...prev, { role: 'assistant', content: '' }]);
      
      const response = await chatService.sendMessage(
        messages.concat(newMessage),
        (content: string) => {
          setStreamingContent(content);
        }
      );

      setMessages(prev => [
        ...prev.slice(0, -1),
        response as ChatMessage
      ]);
      
      unlockBadge('chat_started');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
      setMessages(prev => prev.slice(0, -1));
    } finally {
      setIsLoading(false);
      setStreamingContent('');
    }
  };

  return (
    <div className="h-[calc(100vh-12rem)] flex flex-col">
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-3 ${
                message.role === 'user'
                  ? 'bg-green-500/20 ml-4'
                  : 'bg-gray-800 mr-4'
              }`}
            >
              <p className="terminal-text whitespace-pre-wrap">
                {index === messages.length - 1 && message.role === 'assistant'
                  ? streamingContent || message.content
                  : message.content}
              </p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {error && (
        <div className="p-4 bg-red-500/10 border border-red-500/30 rounded-lg mx-4 mb-4">
          <p className="text-red-400 terminal-text">{error}</p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="p-4 border-t border-green-500/30">
        <div className="flex space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={isLoading ? "Hal is thinking..." : "Ask Hal anything..."}
            disabled={isLoading}
            className="flex-1 bg-gray-800 text-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500/50 terminal-text"
          />
          <button
            type="submit"
            disabled={isLoading}
            className="bg-green-500/20 text-green-400 p-2 rounded-lg hover:bg-green-500/30 transition-colors disabled:opacity-50"
          >
            <Send size={20} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Chat;