import React from 'react';
import { Bitcoin, Lock, AlertTriangle } from 'lucide-react';

const BuyABlock: React.FC = () => {
  return (
    <div className="terminal-window p-6 rounded-lg">
      <div className="flex items-center gap-2 mb-6">
        <Bitcoin size={24} className="text-green-400" />
        <h2 className="text-2xl font-bold terminal-text">Buy-a-Block</h2>
      </div>

      <div className="flex flex-col items-center justify-center space-y-6 py-12">
        <div className="flex items-center justify-center w-16 h-16 rounded-full bg-green-500/10 mb-4">
          <Lock size={32} className="text-green-400" />
        </div>

        <div className="max-w-lg text-center space-y-4">
          <h3 className="text-xl font-bold terminal-text">Coming Soon</h3>
          
          <p className="terminal-text opacity-80">
            Buy-a-Block will allow you to permanently own a piece of this digital memorial.
            With 21 million pixels available (one for each Bitcoin), you'll be able to add
            your mark to history.
          </p>

          <div className="flex items-start gap-2 bg-green-500/10 p-4 rounded-lg text-left">
            <AlertTriangle size={20} className="flex-shrink-0 mt-1" />
            <div className="space-y-2">
              <p className="terminal-text text-sm">
                Features will include:
              </p>
              <ul className="list-disc list-inside space-y-1 terminal-text text-sm opacity-80">
                <li>Purchase individual or multiple blocks</li>
                <li>Add text, emojis, or images to your blocks</li>
                <li>Pay with Bitcoin Lightning Network</li>
                <li>Permanent ownership on the memorial</li>
                <li>View the complete history of the canvas</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyABlock;