import React from 'react';
import { Trophy, Star, Lock } from 'lucide-react';
import { useBadgeStore } from '../../store/badgeStore';

const AchievementsPreview: React.FC = () => {
  const { badges, unlockedBadges } = useBadgeStore();

  // Filter out secret badges for the preview
  const visibleBadges = Object.values(badges).filter(badge => !badge.secret);
  const unlockedCount = unlockedBadges.length;
  const totalBadges = visibleBadges.length;

  // Group badges by category
  const categories = {
    visitor: visibleBadges.filter(b => b.id.includes('visit') || b.id.includes('early')),
    interaction: visibleBadges.filter(b => b.id.includes('chat') || b.id.includes('archive')),
    challenges: visibleBadges.filter(b => b.id.includes('challenge')),
    games: visibleBadges.filter(b => b.id.includes('miles') || b.id.includes('runner')),
    special: visibleBadges.filter(b => b.id.includes('bitcoin') || b.id.includes('genesis'))
  };

  return (
    <div className="terminal-window p-6 rounded-lg">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Trophy className="text-green-400" size={24} />
          <h2 className="text-2xl font-bold terminal-text">Achievements</h2>
        </div>
        <div className="text-sm terminal-text">
          {unlockedCount} / {totalBadges} Unlocked
          <div className="text-xs opacity-70">(+ Secret Achievements)</div>
        </div>
      </div>

      <div className="space-y-6">
        {Object.entries(categories).map(([category, categoryBadges]) => (
          <div key={category}>
            <h3 className="text-lg font-bold mb-3 capitalize terminal-text">
              {category} Achievements
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {categoryBadges.map(badge => {
                const isUnlocked = unlockedBadges.includes(badge.id);
                return (
                  <div
                    key={badge.id}
                    className={`
                      p-4 rounded-lg border transition-all
                      ${isUnlocked 
                        ? 'border-green-500 bg-green-500/10' 
                        : 'border-gray-500/30 opacity-70'}
                    `}
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-2xl">{badge.icon}</span>
                        <div>
                          <h4 className="font-bold terminal-text">{badge.name}</h4>
                          <p className="text-sm opacity-70 terminal-text">
                            {badge.description}
                          </p>
                        </div>
                      </div>
                      {isUnlocked ? (
                        <Star size={16} className="text-green-400" />
                      ) : (
                        <Lock size={16} className="opacity-50" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        <div className="mt-8 p-4 bg-green-500/10 rounded-lg">
          <h3 className="text-lg font-bold mb-2 terminal-text">🤫 Secret Achievements</h3>
          <p className="text-sm terminal-text">
            There are hidden achievements waiting to be discovered! Explore the site, check the console,
            and look for hidden patterns to unlock them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AchievementsPreview;