import { z } from 'zod';

const priceSchema = z.object({
  bitcoin: z.object({
    usd: z.number()
  })
});

const binanceSchema = z.object({
  price: z.string().transform(Number)
});

export async function getBitcoinPrice(): Promise<number | null> {
  const sources = [
    {
      url: 'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd',
      schema: priceSchema,
      extract: (data: z.infer<typeof priceSchema>) => data.bitcoin.usd
    },
    {
      url: 'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT',
      schema: binanceSchema,
      extract: (data: z.infer<typeof binanceSchema>) => data.price
    }
  ];

  for (const source of sources) {
    try {
      const response = await fetch(source.url);
      if (!response.ok) continue;
      
      const data = await response.json();
      const parsed = source.schema.safeParse(data);
      
      if (parsed.success) {
        return source.extract(parsed.data);
      }
    } catch (error) {
      console.warn(`Failed to fetch from ${source.url}:`, error);
      continue;
    }
  }

  // Fallback static price if all APIs fail
  return 65000;
}

export async function getBlockHeight(): Promise<number | null> {
  try {
    const response = await fetch('https://mempool.space/api/blocks/tip/height');
    if (!response.ok) return null;
    const height = await response.text();
    return parseInt(height, 10);
  } catch (error) {
    // Fallback to recent block height
    return 830000;
  }
}

export async function getNetworkFees(): Promise<number | null> {
  try {
    const response = await fetch('https://mempool.space/api/v1/fees/recommended');
    if (!response.ok) return null;
    const data = await response.json();
    return data.fastestFee;
  } catch (error) {
    // Fallback to reasonable fee
    return 50;
  }
}