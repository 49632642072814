import React from 'react';
import { Link2, Github, Twitter, MessageCircle } from 'lucide-react';

const Resources = () => {
  const resources = [
    {
      category: 'Social Media & Forums',
      items: [
        {
          title: 'BitcoinTalk Profile',
          url: 'https://bitcointalk.org/index.php?action=profile;u=2436',
          icon: MessageCircle,
          description: 'Hal\'s contributions to the Bitcoin forum, including technical discussions and early Bitcoin development.'
        },
        {
          title: 'GitHub',
          url: 'https://github.com/halfinney',
          icon: Github,
          description: 'Open source contributions and cryptographic projects.'
        },
        {
          title: 'Twitter Memorial',
          url: 'https://x.com/running_hal_ai',
          icon: Twitter,
          description: 'Digital memorial account preserving Hal\'s legacy.'
        }
      ]
    },
    {
      category: 'Articles & Tributes',
      items: [
        {
          title: 'Last BitcoinTalk Post',
          url: 'https://www.reddit.com/r/Bitcoin/comments/7jsp0s/one_of_the_last_posts_made_by_hal_finney_on/',
          icon: Link2,
          description: 'One of Hal\'s final contributions to the Bitcoin community.'
        },
        {
          title: 'CoinDesk Coverage',
          url: 'https://www.coindesk.com/tag/hal-finney/',
          icon: Link2,
          description: 'Collection of articles about Hal\'s impact on cryptocurrency.'
        },
        {
          title: 'Legacy Overview',
          url: 'https://finchtrade.com/glossary/hal-finney',
          icon: Link2,
          description: 'Comprehensive overview of Hal\'s contributions to cryptography and Bitcoin.'
        }
      ]
    }
  ];

  const quotes = [
    {
      text: "Running bitcoin",
      date: "January 11, 2009",
      context: "First ever tweet about Bitcoin"
    },
    {
      text: "I've noticed that of the 18.3 million Bitcoin that have been mined so far, my five transactions of 10 Bitcoins each were probably the first ones after Satoshi's initial 50.",
      date: "March 19, 2013",
      context: "BitcoinTalk forum post"
    },
    {
      text: "I'm pretty lucky overall. Even with the ALS, my life is very satisfying.",
      date: "March 19, 2013",
      context: "BitcoinTalk forum post"
    }
  ];

  return (
    <div className="space-y-8">
      <section>
        <h2 className="text-2xl font-bold mb-4 flex items-center gap-2">
          <Link2 size={24} />
          Resources & Links
        </h2>
        
        {resources.map((section, idx) => (
          <div key={idx} className="mb-8">
            <h3 className="text-xl font-semibold mb-4 text-green-400">{section.category}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {section.items.map((item, i) => {
                const Icon = item.icon;
                return (
                  <a
                    key={i}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border border-green-500/30 rounded-lg p-4 hover:bg-green-500/5 transition-colors"
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <Icon size={20} />
                      <span className="font-semibold">{item.title}</span>
                    </div>
                    <p className="text-sm opacity-80">{item.description}</p>
                  </a>
                );
              })}
            </div>
          </div>
        ))}
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">Notable Quotes</h2>
        <div className="space-y-4">
          {quotes.map((quote, idx) => (
            <blockquote
              key={idx}
              className="border-l-4 border-green-500/50 pl-4 py-2"
            >
              <p className="text-lg mb-2">"{quote.text}"</p>
              <footer className="text-sm opacity-80">
                <span className="font-semibold">{quote.date}</span>
                {' - '}
                <span>{quote.context}</span>
              </footer>
            </blockquote>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Resources;