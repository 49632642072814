import React from 'react';
import { Coins, ExternalLink, Calendar, Info } from 'lucide-react';

const Transactions: React.FC = () => {
  const transactions = [
    {
      hash: 'f4184fc596403b9d638783cf57adfe4c75c605f6356fbc91338530e9831e9e16',
      date: '2009-01-12',
      from: 'Satoshi Nakamoto',
      to: 'Hal Finney',
      amount: 10,
      block: 170,
      note: 'First ever Bitcoin transaction'
    },
    {
      hash: '9b0fc92260312ce44e74ef369f5c66bbb85848f2eddd5a7a1cde251e54ccfdd5',
      date: '2009-01-12',
      from: 'Satoshi Nakamoto',
      to: 'Hal Finney',
      amount: 10,
      block: 181,
      note: 'Testing transaction'
    },
    {
      hash: '7dff938918f07619abd38e4510890396b1cef4fbeca154fb7aafba8843295ea2',
      date: '2009-01-12',
      from: 'Block Reward',
      to: 'Hal Finney',
      amount: 50,
      block: 78,
      note: 'Mining reward'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Coins size={24} className="text-green-400" />
          <h2 className="text-2xl font-bold terminal-text">Historical Transactions</h2>
        </div>
        
        <div className="flex items-center gap-2 text-sm opacity-70">
          <Calendar size={16} />
          <span className="terminal-text">January 12, 2009</span>
        </div>
      </div>

      <div className="terminal-window p-4 rounded-lg">
        <div className="mb-4 p-4 bg-green-500/10 rounded-lg flex items-start gap-2">
          <Info size={20} className="flex-shrink-0 mt-1" />
          <p className="text-sm terminal-text">
            These transactions represent the earliest Bitcoin transfers in history, 
            including the very first transaction from Satoshi Nakamoto to Hal Finney 
            in block 170.
          </p>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-green-500/30">
                <th className="px-4 py-2 text-left terminal-text">Date</th>
                <th className="px-4 py-2 text-left terminal-text">From</th>
                <th className="px-4 py-2 text-left terminal-text">To</th>
                <th className="px-4 py-2 text-right terminal-text">Amount (BTC)</th>
                <th className="px-4 py-2 text-right terminal-text">Block</th>
                <th className="px-4 py-2 text-left terminal-text">Note</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((tx, index) => (
                <tr
                  key={index}
                  className="border-b border-green-500/10 hover:bg-green-500/5 transition-colors"
                >
                  <td className="px-4 py-2 terminal-text">{tx.date}</td>
                  <td className="px-4 py-2 terminal-text">{tx.from}</td>
                  <td className="px-4 py-2 terminal-text">{tx.to}</td>
                  <td className="px-4 py-2 text-right terminal-text">{tx.amount}</td>
                  <td className="px-4 py-2 text-right terminal-text">{tx.block}</td>
                  <td className="px-4 py-2 terminal-text opacity-70">{tx.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 text-sm">
          <a
            href={`https://blockstream.info/tx/${transactions[0].hash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1 text-green-400 hover:underline"
          >
            <ExternalLink size={14} />
            <span className="terminal-text">View first transaction on block explorer</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Transactions;