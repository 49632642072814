import React, { useState } from 'react';
import { Flag, Filter, Award } from 'lucide-react';
import { useBadgeStore } from '../store/badgeStore';
import { challenges } from '../utils/challenges';
import { CHALLENGE_CATEGORIES } from '../types/challenges';
import ChallengeCard from './challenges/ChallengeCard';
import ChallengeSolver from './challenges/ChallengeSolver';

const Challenges: React.FC = () => {
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [filter, setFilter] = useState<string>('all');
  const { unlockedBadges, unlockBadge } = useBadgeStore();
  const [solvedChallenges, setSolvedChallenges] = useState<string[]>([]);

  const handleSolve = (challengeId: string, isCorrect: boolean) => {
    if (isCorrect) {
      setSolvedChallenges(prev => [...prev, challengeId]);
      unlockBadge(`challenge_${challengeId}`);
      
      // Check for special achievements
      if (solvedChallenges.length + 1 === challenges.length) {
        unlockBadge('challenge_master');
      }
    }
  };

  const filteredChallenges = challenges.filter(challenge => 
    filter === 'all' || challenge.category === filter
  );

  const categories = Object.entries(CHALLENGE_CATEGORIES);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Flag className="text-green-400" size={24} />
          <h2 className="text-2xl font-bold terminal-text">Cryptographic Challenges</h2>
        </div>
        
        <div className="flex items-center gap-2">
          <Filter size={16} />
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="bg-black/30 border border-green-500/30 rounded px-3 py-2 terminal-text"
          >
            <option value="all">All Categories</option>
            {categories.map(([key, category]) => (
              <option key={key} value={key}>
                {category.icon} {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {categories.map(([key, category]) => (
          <div
            key={key}
            className="terminal-window p-4 rounded-lg border border-green-500/30"
          >
            <div className="flex items-center gap-2 mb-2">
              <span className="text-2xl">{category.icon}</span>
              <h3 className="text-lg font-bold terminal-text">{category.name}</h3>
            </div>
            <p className="text-sm opacity-70 terminal-text">{category.description}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredChallenges.map((challenge) => (
          <ChallengeCard
            key={challenge.id}
            challenge={challenge}
            isSolved={solvedChallenges.includes(challenge.id)}
            onSelect={() => setSelectedChallenge(challenge)}
          />
        ))}
      </div>

      {selectedChallenge && (
        <ChallengeSolver
          challenge={selectedChallenge}
          onSolve={(isCorrect) => handleSolve(selectedChallenge.id, isCorrect)}
        />
      )}

      <div className="mt-6">
        <h3 className="text-lg font-bold mb-2 flex items-center gap-2 terminal-text">
          <Award size={20} />
          Progress
        </h3>
        <div className="terminal-window p-4 rounded-lg">
          <div className="flex items-center gap-4">
            <div className="flex-1 bg-gray-800 rounded-full h-4">
              <div
                className="bg-green-500 rounded-full h-4 transition-all"
                style={{
                  width: `${(solvedChallenges.length / challenges.length) * 100}%`
                }}
              />
            </div>
            <span className="terminal-text">
              {solvedChallenges.length}/{challenges.length} Completed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenges;