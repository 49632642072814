import { sha256 } from './crypto';

export interface Challenge {
  id: string;
  title: string;
  description: string;
  difficulty: 'Easy' | 'Medium' | 'Hard' | 'Expert';
  category: 'Cryptography' | 'Bitcoin' | 'Programming' | 'History';
  reward: string;
  hint?: string;
  validator: (input: string) => boolean;
}

export const challenges: Challenge[] = [
  // Original challenges
  {
    id: 'rpow_basics',
    title: 'RPOW Implementation',
    description: 'Implement a basic Reusable Proof of Work function. Input should be a string that when hashed with SHA-256 starts with "000".',
    difficulty: 'Medium',
    category: 'Cryptography',
    reward: 'RPOW Pioneer Badge',
    hint: 'Try adding numbers to the end of a string until you get a hash with three leading zeros.',
    validator: async (input: string) => {
      const hash = await sha256(input);
      return hash.startsWith('000');
    }
  },
  {
    id: 'genesis_puzzle',
    title: 'Genesis Block Secret',
    description: 'Find the hidden message in Bitcoin\'s genesis block. Convert the following hex to ASCII: 546865205468696D6573',
    difficulty: 'Easy',
    category: 'Bitcoin',
    reward: 'Genesis Explorer Badge',
    validator: (input: string) => input.toLowerCase() === 'the times'
  },
  // New challenges
  {
    id: 'base58_decode',
    title: 'Base58 Decoder',
    description: 'Decode this Base58 Bitcoin address: 1HALFiNNeYRutH8D9K6x7YuEgRqwFyG9M1',
    difficulty: 'Medium',
    category: 'Bitcoin',
    reward: 'Address Master Badge',
    hint: 'Base58 is used for Bitcoin addresses to avoid similar-looking characters',
    validator: (input: string) => input.toLowerCase() === 'hal finney'
  },
  {
    id: 'script_puzzle',
    title: 'Script Decoder',
    description: 'Complete this Bitcoin Script: OP_DUP OP_HASH160 <pubKeyHash> OP_EQUALVERIFY ___',
    difficulty: 'Medium',
    category: 'Programming',
    reward: 'Script Wizard Badge',
    hint: 'This is the most common Bitcoin output script',
    validator: (input: string) => input.toLowerCase() === 'op_checksig'
  },
  {
    id: 'private_key',
    title: 'Private Key Generator',
    description: 'Create a valid 32-byte private key in hex (must start with "1d" and end with "ff")',
    difficulty: 'Expert',
    category: 'Cryptography',
    reward: 'Key Master Badge',
    validator: (input: string) => /^1d[0-9a-f]{60}ff$/.test(input)
  },
  {
    id: 'merkle_root',
    title: 'Merkle Root Calculator',
    description: 'Calculate Merkle root of these hashes: "aa", "bb". Double-SHA256, take first 2 chars.',
    difficulty: 'Expert',
    category: 'Programming',
    reward: 'Merkle Master Badge',
    hint: 'Concatenate hashes before hashing again',
    validator: async (input: string) => {
      const combined = 'aabb';
      const hash1 = await sha256(combined);
      const hash2 = await sha256(hash1);
      return input.toLowerCase() === hash2.substring(0, 2);
    }
  },
  {
    id: 'cypherpunk_manifesto',
    title: 'Cypherpunk Manifesto',
    description: 'Find the author of "A Cypherpunk\'s Manifesto" (first name only)',
    difficulty: 'Easy',
    category: 'History',
    reward: 'Cypherpunk Badge',
    validator: (input: string) => input.toLowerCase() === 'eric'
  },
  {
    id: 'hidden_message',
    title: 'Hidden Message',
    description: 'Find the hidden ASCII art message in the page source',
    difficulty: 'Medium',
    category: 'Programming',
    reward: 'Explorer Badge',
    hint: 'Check the HTML comments',
    validator: (input: string) => input === 'H4L-F1NN3Y-CRYPT0-CH4LL3NG3-2024'
  }
];