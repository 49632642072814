import React, { useState, useEffect } from 'react';
import { ShoppingBag, Lock, Key, Package, Coins, AlertTriangle } from 'lucide-react';
import { useBadgeStore } from '../store/badgeStore';

interface PackListing {
  id: string;
  name: string;
  description: string;
  price: number;
  contents: string;
  rarity: 'common' | 'rare' | 'legendary' | 'mythic';
  requiresPuzzle: boolean;
  puzzle?: {
    question: string;
    hint: string;
    answer: string;
  };
}

const ShadowMarket: React.FC = () => {
  const [selectedPack, setSelectedPack] = useState<PackListing | null>(null);
  const [puzzleAnswer, setPuzzleAnswer] = useState('');
  const [error, setError] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const { unlockBadge } = useBadgeStore();
  const [accessCode, setAccessCode] = useState('');

  const packs: PackListing[] = [
    {
      id: 'genesis_pack',
      name: 'Genesis Block Pack',
      description: 'Contains early Bitcoin history cards',
      price: 0.001,
      contents: '5 cards, guaranteed 1 Pioneer card',
      rarity: 'legendary',
      requiresPuzzle: true,
      puzzle: {
        question: 'What was embedded in the Genesis Block?',
        hint: 'Check The Times headline from January 3rd, 2009',
        answer: 'chancellor'
      }
    },
    {
      id: 'cypherpunk_pack',
      name: 'Cypherpunk Collection',
      description: 'Cryptography pioneers and early digital currency attempts',
      price: 0.0015,
      contents: '5 cards, includes pre-Bitcoin experiments',
      rarity: 'mythic',
      requiresPuzzle: true,
      puzzle: {
        question: 'Complete: We the _______ are rebooting the system',
        hint: 'Check the Cypherpunk Manifesto',
        answer: 'cypherpunks'
      }
    },
    {
      id: 'hack_pack',
      name: 'Security Incidents Pack',
      description: 'Famous hacks, exploits, and security lessons',
      price: 0.0005,
      contents: '3 cards, includes defensive measures',
      rarity: 'rare',
      requiresPuzzle: false
    }
  ];

  const verifyMarketAccess = (code: string) => {
    // Simple ROT13 of "satoshi" is "fngbfuv"
    if (code.toLowerCase() === 'fngbfuv') {
      setAccessGranted(true);
      unlockBadge('shadow_market');
      return true;
    }
    return false;
  };

  const handleAccessAttempt = () => {
    if (verifyMarketAccess(accessCode)) {
      setError('');
    } else {
      setError('Invalid access code. Hint: ROT13("satoshi")');
    }
  };

  const handlePuzzleSubmit = (pack: PackListing) => {
    if (pack.puzzle && puzzleAnswer.toLowerCase() === pack.puzzle.answer.toLowerCase()) {
      // Simulate purchase
      simulatePurchase(pack);
      setPuzzleAnswer('');
      setError('');
    } else {
      setError('Incorrect answer. Try again.');
    }
  };

  const simulatePurchase = (pack: PackListing) => {
    // Simulate blockchain transaction
    const txHash = Array(64).fill(0).map(() => 
      Math.floor(Math.random() * 16).toString(16)
    ).join('');

    alert(`
      Transaction successful!
      Pack: ${pack.name}
      Price: ${pack.price} BTC
      TX: ${txHash}
      
      Your cards will appear in your collection shortly.
    `);
  };

  if (!accessGranted) {
    return (
      <div className="terminal-window p-6 rounded-lg">
        <div className="flex items-center gap-2 mb-6">
          <Lock className="text-green-400" size={24} />
          <h2 className="text-2xl font-bold terminal-text">Shadow Market Access</h2>
        </div>

        <div className="space-y-4">
          <p className="terminal-text">
            ⚠️ WARNING: This is a restricted area. Access requires cryptographic verification.
          </p>
          
          <div className="flex gap-2">
            <input
              type="text"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              placeholder="Enter access code..."
              className="flex-1 bg-black/30 border border-green-500/30 rounded px-3 py-2 terminal-text"
            />
            <button
              onClick={handleAccessAttempt}
              className="retro-button px-4 py-2 rounded terminal-text"
            >
              <Key size={16} className="inline mr-2" />
              Verify
            </button>
          </div>

          {error && (
            <p className="text-red-400 terminal-text">
              <AlertTriangle size={16} className="inline mr-2" />
              {error}
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2 mb-6">
        <ShoppingBag className="text-green-400" size={24} />
        <h2 className="text-2xl font-bold terminal-text">Shadow Market</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {packs.map((pack) => (
          <div
            key={pack.id}
            className="terminal-window p-4 rounded-lg border border-green-500/30 hover:bg-green-500/5 transition-all"
          >
            <div className="flex justify-between items-start mb-2">
              <h3 className="text-lg font-bold terminal-text">{pack.name}</h3>
              <span className={`
                text-sm px-2 py-1 rounded-full
                ${pack.rarity === 'mythic' ? 'bg-orange-500/20' :
                  pack.rarity === 'legendary' ? 'bg-purple-500/20' :
                  pack.rarity === 'rare' ? 'bg-blue-500/20' :
                  'bg-green-500/20'}
              `}>
                {pack.rarity}
              </span>
            </div>

            <p className="text-sm mb-4 terminal-text">{pack.description}</p>
            <p className="text-sm mb-4 terminal-text">
              <Package size={14} className="inline mr-2" />
              {pack.contents}
            </p>

            <div className="flex justify-between items-center mb-4">
              <span className="flex items-center gap-1 terminal-text">
                <Coins size={14} />
                {pack.price} BTC
              </span>
            </div>

            {pack.requiresPuzzle ? (
              <div className="space-y-2">
                <p className="text-sm terminal-text">{pack.puzzle?.question}</p>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={puzzleAnswer}
                    onChange={(e) => setPuzzleAnswer(e.target.value)}
                    placeholder="Enter answer..."
                    className="flex-1 bg-black/30 border border-green-500/30 rounded px-3 py-2 text-sm terminal-text"
                  />
                  <button
                    onClick={() => handlePuzzleSubmit(pack)}
                    className="retro-button px-4 py-2 rounded terminal-text"
                  >
                    Unlock
                  </button>
                </div>
                <p className="text-xs opacity-50 terminal-text">
                  💡 Hint: {pack.puzzle?.hint}
                </p>
              </div>
            ) : (
              <button
                onClick={() => simulatePurchase(pack)}
                className="retro-button w-full px-4 py-2 rounded terminal-text"
              >
                Purchase Pack
              </button>
            )}
          </div>
        ))}
      </div>

      {error && (
        <p className="text-red-400 terminal-text">
          <AlertTriangle size={16} className="inline mr-2" />
          {error}
        </p>
      )}
    </div>
  );
};

export default ShadowMarket;