import React, { useState, useEffect } from 'react';
import { Coins, ExternalLink } from 'lucide-react';

const RunesPromotion: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && !hasScrolled) {
        setHasScrolled(true);
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-40 w-full max-w-2xl px-4">
      <div className="terminal-window p-4 rounded-lg border-2 border-green-500/30 backdrop-blur-sm">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-3">
            <div className="text-2xl animate-pulse">₿</div>
            <div>
              <h3 className="font-bold terminal-text">Trade RUNNING•HAL•AI Runes</h3>
              <p className="text-sm opacity-70 terminal-text">
                Join the community and trade Hal Finney memorial Runes on Magic Eden
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <a
              href="https://magiceden.io/runes/RUNNING•HAL•AI"
              target="_blank"
              rel="noopener noreferrer"
              className="retro-button px-4 py-2 rounded terminal-text flex items-center gap-2 hover:bg-green-500/10"
            >
              <Coins size={16} />
              Trade Now
              <ExternalLink size={14} />
            </a>
            <button
              onClick={() => setIsVisible(false)}
              className="text-green-400 hover:text-green-300 p-1"
            >
              ×
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunesPromotion;