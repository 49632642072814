import React, { useState } from 'react';
import { Shield, Zap } from 'lucide-react';
import type { Challenge } from '../../types/challenges';

interface ChallengeSolverProps {
  challenge: Challenge;
  onSolve: (result: boolean) => void;
}

const ChallengeSolver: React.FC<ChallengeSolverProps> = ({ challenge, onSolve }) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    try {
      const isCorrect = await challenge.validator(input.trim());
      if (isCorrect) {
        onSolve(true);
        setError('');
        setInput('');
      } else {
        setError('Incorrect solution. Try again.');
      }
    } catch (err) {
      setError('An error occurred while validating your solution.');
    }
  };

  return (
    <div className="terminal-window p-6 rounded-lg">
      <h3 className="text-xl font-bold mb-4 terminal-text">{challenge.title}</h3>
      <p className="mb-4 terminal-text">{challenge.description}</p>
      
      {challenge.hint && (
        <div className="mb-4 p-4 bg-green-500/10 rounded-lg">
          <p className="text-sm terminal-text">
            <span className="font-bold">Hint:</span> {challenge.hint}
          </p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex gap-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter your solution..."
            className="flex-1 bg-black/30 border border-green-500/30 rounded px-3 py-2 terminal-text"
          />
          <button
            type="submit"
            className="retro-button px-4 py-2 rounded terminal-text"
          >
            Submit
          </button>
        </div>

        {error && (
          <p className="text-red-400 terminal-text">{error}</p>
        )}
      </form>

      <div className="mt-4 flex items-center gap-2">
        <Zap size={16} className="text-green-400" />
        <span className="terminal-text">Reward: {challenge.reward}</span>
      </div>
    </div>
  );
};

export default ChallengeSolver;