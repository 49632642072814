import React from 'react';
import { Archive } from 'lucide-react';

const Archives = () => {
  const emails = [
    {
      date: '2009-01-11',
      from: 'satoshi@anonymously.com',
      to: 'hal.finney@gmail.com',
      subject: 'Bitcoin v0.1 released',
      preview: 'Announcing the first release of Bitcoin, a new electronic cash system that uses a peer-to-peer network to prevent double-spending...',
    },
    {
      date: '2009-01-11',
      from: 'hal.finney@gmail.com',
      to: 'satoshi@anonymously.com',
      subject: 'Re: Bitcoin v0.1 released',
      preview: 'When you develop something that is potentially revolutionary like this, you can\'t help but feel some pride, and I think it\'s entirely deserved...',
    },
    // Add more historical emails
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold flex items-center gap-2">
        <Archive size={24} />
        Email Archives
      </h2>
      
      <div className="space-y-4">
        {emails.map((email, index) => (
          <div
            key={index}
            className="border border-green-500/30 rounded-lg p-4 hover:bg-green-500/5 transition-colors cursor-pointer"
          >
            <div className="flex justify-between text-sm opacity-70 mb-2">
              <span>{email.date}</span>
              <span>{email.from}</span>
            </div>
            <h3 className="text-lg font-semibold mb-2">{email.subject}</h3>
            <p className="opacity-80">{email.preview}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Archives;