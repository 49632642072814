import React, { useState } from 'react';
import { Trophy, Share2, X, Twitter, Facebook, Linkedin } from 'lucide-react';
import { useBadgeStore } from '../store/badgeStore';

const BadgeDisplay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { badges, unlockedBadges } = useBadgeStore();

  const handleShare = (platform: 'twitter' | 'facebook' | 'linkedin', badge: string) => {
    const badgeDetails = badges[badge];
    const text = `I just earned the ${badgeDetails.name} badge on Hal Finney's Digital Memorial! ${badgeDetails.description}`;
    
    const urls = {
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(text)}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent('Badge Earned!')}&summary=${encodeURIComponent(text)}`
    };

    window.open(urls[platform], '_blank', 'width=600,height=400');
  };

  if (unlockedBadges.length === 0) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={() => setIsOpen(true)}
        className="retro-button p-2 rounded-full relative"
      >
        <Trophy size={24} />
        <span className="absolute -top-2 -right-2 bg-green-500 text-black w-6 h-6 rounded-full flex items-center justify-center text-sm">
          {unlockedBadges.length}
        </span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4">
          <div className="terminal-window w-full max-w-2xl p-6 rounded-lg relative">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-4 right-4 retro-button p-2 rounded hover:bg-green-500/10"
            >
              <X size={20} />
            </button>

            <h2 className="text-2xl font-bold mb-6 terminal-text flex items-center gap-2">
              <Trophy size={24} />
              Earned Badges
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {unlockedBadges.map(badgeId => {
                const badge = badges[badgeId];
                const isUnlocked = true;

                return (
                  <div
                    key={badge.id}
                    className={`
                      relative p-4 rounded-lg border-2 transition-all duration-300
                      ${isUnlocked ? 'badge-unlock' : ''}
                      ${isUnlocked
                        ? 'border-green-500 bg-green-500/10 shadow-[0_0_10px_rgba(0,255,0,0.2)]'
                        : 'border-gray-700 bg-black/50'}
                    `}
                  >
                    <div className="flex items-start justify-between">
                      <span className="text-2xl">{badge.icon}</span>
                      <div className="flex gap-1">
                        <button
                          onClick={() => handleShare('twitter', badge.id)}
                          className="retro-button p-1 rounded hover:bg-green-500/10"
                          title="Share on Twitter"
                        >
                          <Twitter size={14} />
                        </button>
                        <button
                          onClick={() => handleShare('facebook', badge.id)}
                          className="retro-button p-1 rounded hover:bg-green-500/10"
                          title="Share on Facebook"
                        >
                          <Facebook size={14} />
                        </button>
                        <button
                          onClick={() => handleShare('linkedin', badge.id)}
                          className="retro-button p-1 rounded hover:bg-green-500/10"
                          title="Share on LinkedIn"
                        >
                          <Linkedin size={14} />
                        </button>
                      </div>
                    </div>
                    <h3 className="font-bold mt-2 terminal-text">{badge.name}</h3>
                    <p className="text-sm opacity-70 mt-1 terminal-text">
                      {badge.description}
                    </p>
                    {badge.unlockedAt && (
                      <p className="text-xs opacity-50 mt-2 terminal-text">
                        Earned: {new Date(badge.unlockedAt).toLocaleDateString()}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BadgeDisplay;