import React from 'react';
import { Shield, Lock } from 'lucide-react';
import type { Challenge } from '../../types/challenges';

interface ChallengeCardProps {
  challenge: Challenge;
  isSolved: boolean;
  onSelect: () => void;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge, isSolved, onSelect }) => {
  const difficultyColors = {
    Easy: 'bg-green-500/20 text-green-400',
    Medium: 'bg-yellow-500/20 text-yellow-400',
    Hard: 'bg-orange-500/20 text-orange-400',
    Expert: 'bg-red-500/20 text-red-400'
  };

  return (
    <div
      onClick={onSelect}
      className={`
        terminal-window p-4 rounded-lg border-2 transition-all cursor-pointer
        ${isSolved ? 'border-green-500 bg-green-500/10' : 'border-green-500/30'}
        hover:bg-green-500/5
      `}
    >
      <div className="flex justify-between items-start mb-2">
        <h3 className="text-lg font-bold terminal-text">{challenge.title}</h3>
        <span className={`text-sm px-2 py-1 rounded-full ${difficultyColors[challenge.difficulty]}`}>
          {challenge.difficulty}
        </span>
      </div>

      <p className="text-sm mb-4 terminal-text">{challenge.description}</p>

      <div className="flex justify-between items-center">
        <span className="text-sm opacity-70 terminal-text">{challenge.category}</span>
        {isSolved ? (
          <span className="flex items-center gap-1 text-green-400">
            <Shield size={14} />
            <span className="terminal-text">Solved</span>
          </span>
        ) : (
          <span className="flex items-center gap-1 opacity-70">
            <Lock size={14} />
            <span className="terminal-text">Unsolved</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default ChallengeCard;