import React, { useEffect } from 'react';
import { Trophy, X } from 'lucide-react';

interface ToastProps {
  badge: {
    name: string;
    description: string;
    icon: string;
  };
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ badge, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="achievement-popup fixed bottom-20 right-4 bg-green-500/10 border-2 border-green-500/30 rounded-lg p-4 max-w-sm shadow-lg backdrop-blur-sm">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 hover:text-green-400 transition-colors"
      >
        <X size={16} />
      </button>
      
      <div className="flex items-start gap-3">
        <div className="flex-shrink-0">
          <Trophy className="text-green-400" size={24} />
        </div>
        <div>
          <div className="flex items-center gap-2">
            <span className="text-xl">{badge.icon}</span>
            <h3 className="font-bold terminal-text">Achievement Unlocked!</h3>
          </div>
          <p className="font-semibold mt-1 terminal-text">{badge.name}</p>
          <p className="text-sm opacity-70 mt-1 terminal-text">{badge.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Toast;