import React from 'react';
import { NavLink } from 'react-router-dom';
import { Terminal, MessageSquare, Archive, Coins, Flag, Link2, PlaySquare, ShoppingBag, Building2, Grid } from 'lucide-react';

const Navigation = () => {
  const navItems = [
    { path: '/', icon: Terminal, label: 'Terminal' },
    { path: '/chat', icon: MessageSquare, label: 'Chat with Hal' },
    { path: '/archives', icon: Archive, label: 'Archives' },
    { path: '/transactions', icon: Coins, label: 'Transactions' },
    { path: '/challenges', icon: Flag, label: 'Challenges' },
    { path: '/miles', icon: PlaySquare, label: 'Miles with Hal' },
    { path: '/museum', icon: Building2, label: 'Museum' },
    { path: '/resources', icon: Link2, label: 'Resources' },
    { path: '/tcg', icon: PlaySquare, label: 'Crypto TCG' },
    { path: '/market', icon: ShoppingBag, label: 'Shadow Market' },
    { path: '/blocks', icon: Grid, label: 'Buy-a-Block' }
  ];

  return (
    <nav className="space-y-2">
      {navItems.map(({ path, icon: Icon, label }) => (
        <NavLink
          key={path}
          to={path}
          className={({ isActive }) =>
            `flex items-center space-x-2 p-2 rounded-lg transition-colors ${
              isActive
                ? 'bg-green-500/20 text-green-400'
                : 'hover:bg-green-500/10 text-green-400/70'
            }`
          }
        >
          <Icon size={20} />
          <span className="terminal-text">{label}</span>
        </NavLink>
      ))}
    </nav>
  );
};

export default Navigation;