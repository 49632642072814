import React, { useState, useEffect, useRef } from 'react';
import { useBadgeStore } from '../store/badgeStore';
import { Share2 } from 'lucide-react';

const GAME_WIDTH = 800; // Game width in pixels
const INITIAL_SPEED = 5; // Initial obstacle speed
const SPEED_INCREMENT = 0.1; // Smaller speed increments
const INITIAL_DIFFICULTY = 0.005; // Lower initial spawn rate
const DIFFICULTY_INCREMENT = 0.0001; // Smaller difficulty increments
const SCORE_INCREMENT_THRESHOLD = 500; // More time between difficulty increases
const PLAYER_CHAR = '🏃‍♂️';
const OBSTACLE_CHAR = '🌵';
const JUMP_HEIGHT = 100; // Increased jump height
const JUMP_DURATION = 600; // Longer jump duration
const COLLISION_THRESHOLD = 30; // Smaller collision box

const MilesGame: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [position, setPosition] = useState(0);
  const [obstacles, setObstacles] = useState<number[]>([]);
  const [health, setHealth] = useState(100);
  const [speed, setSpeed] = useState(INITIAL_SPEED);
  const [difficulty, setDifficulty] = useState(INITIAL_DIFFICULTY);
  const gameLoopRef = useRef<number>();
  const lastSpawnRef = useRef(0);
  const { unlockBadge } = useBadgeStore();

  const handleKeyPress = (e: KeyboardEvent) => {
    if ((e.code === 'Space' || e.code === 'ArrowUp') && position === 0) {
      setPosition(JUMP_HEIGHT);
      setTimeout(() => setPosition(0), JUMP_DURATION);
    }
  };

  const startGame = () => {
    setIsPlaying(true);
    setGameOver(false);
    setScore(0);
    setPosition(0);
    setObstacles([]);
    setHealth(100);
    setSpeed(INITIAL_SPEED);
    setDifficulty(INITIAL_DIFFICULTY);
    lastSpawnRef.current = 0;
    unlockBadge('miles_runner');
  };

  const endGame = () => {
    setIsPlaying(false);
    setGameOver(true);
    if (score > highScore) {
      setHighScore(score);
      if (score > 1000) {
        unlockBadge('miles_master');
      }
    }
  };

  const shareScore = () => {
    const text = `I just ran ${score} miles with Hal in the Miles with Hal game! Can you beat my score? #HalFinney #Bitcoin`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`, '_blank');
  };

  useEffect(() => {
    if (isPlaying) {
      const handleGameLoop = () => {
        const now = Date.now();
        
        // Increase difficulty over time
        if (score > 0 && score % SCORE_INCREMENT_THRESHOLD === 0) {
          setSpeed(prev => Math.min(prev + SPEED_INCREMENT, 15));
          setDifficulty(prev => Math.min(prev + DIFFICULTY_INCREMENT, 0.02));
        }

        // Update obstacles
        setObstacles(prev => {
          const newObstacles = prev
            .map(x => x - speed)
            .filter(x => x > -50);

          // Add new obstacles with minimum spacing
          if (now - lastSpawnRef.current > 2000 && Math.random() < difficulty) {
            lastSpawnRef.current = now;
            newObstacles.push(GAME_WIDTH);
          }

          return newObstacles;
        });

        // Update score
        setScore(prev => prev + 1);

        // Check collisions
        const playerX = 100; // Fixed player x position
        const playerWidth = 30;
        const obstacleWidth = 20;

        if (obstacles.some(obstacleX => {
          const collision = 
            position === 0 && // Only check when player is on the ground
            Math.abs(obstacleX - playerX) < COLLISION_THRESHOLD &&
            Math.abs(obstacleX - playerX) < (playerWidth + obstacleWidth) / 2;
          return collision;
        })) {
          setHealth(prev => {
            const newHealth = prev - 10;
            if (newHealth <= 0) {
              endGame();
              return 0;
            }
            return newHealth;
          });
        }

        gameLoopRef.current = requestAnimationFrame(handleGameLoop);
      };

      gameLoopRef.current = requestAnimationFrame(handleGameLoop);
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        if (gameLoopRef.current) {
          cancelAnimationFrame(gameLoopRef.current);
        }
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [isPlaying, obstacles, position, difficulty, speed, score]);

  return (
    <div className="terminal-window p-6 rounded-lg">
      <div className="mb-4 flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold terminal-text">Miles with Hal</h2>
          <p className="text-sm opacity-70 terminal-text">Press SPACE or UP ARROW to jump</p>
        </div>
        <div className="text-right">
          <p className="terminal-text">Score: {score}</p>
          <p className="terminal-text">High Score: {highScore}</p>
          <p className="terminal-text">Health: {health}%</p>
        </div>
      </div>

      <div className="h-48 border-2 border-green-500/30 rounded-lg mb-4 relative overflow-hidden">
        <div className="absolute bottom-0 left-0 w-full h-px bg-green-500/30"></div>
        <div
          className="absolute bottom-4 left-20 transition-transform duration-300 scale-x-[-1]"
          style={{ transform: `translateY(-${position}px) scaleX(-1)` }}
        >
          {PLAYER_CHAR}
        </div>
        {obstacles.map((x, i) => (
          <div
            key={i}
            className="absolute bottom-4 text-xl"
            style={{ left: `${x}px` }}
          >
            {OBSTACLE_CHAR}
          </div>
        ))}
      </div>

      <div className="flex gap-4">
        {!isPlaying && (
          <button
            onClick={startGame}
            className="retro-button px-4 py-2 rounded terminal-text"
          >
            {gameOver ? 'Try Again' : 'Start Game'}
          </button>
        )}
        {gameOver && (
          <button
            onClick={shareScore}
            className="retro-button px-4 py-2 rounded terminal-text flex items-center gap-2"
          >
            <Share2 size={16} />
            Share Score
          </button>
        )}
      </div>
    </div>
  );
};

export default MilesGame;