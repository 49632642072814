export const glitchAnimation = (text: string) => {
  const glitchChars = '!@#$%^&*()_+-=[]{}|;:,.<>?';
  let result = text;
  const positions = new Set();
  const numGlitches = Math.floor(text.length * 0.1); // Glitch 10% of characters

  for (let i = 0; i < numGlitches; i++) {
    const pos = Math.floor(Math.random() * text.length);
    if (!positions.has(pos) && text[pos] !== '\n') {
      positions.add(pos);
      const glitchChar = glitchChars[Math.floor(Math.random() * glitchChars.length)];
      result = result.substring(0, pos) + glitchChar + result.substring(pos + 1);
    }
  }

  return result;
};

export const matrixRain = (text: string) => {
  return text.split('\n').map((line, i) => {
    const delay = i * 50; // Stagger each line
    return `<div class="matrix-line" style="animation-delay: ${delay}ms">${line}</div>`;
  }).join('\n');
};