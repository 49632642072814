import React, { useState, useEffect } from 'react';
import { Bitcoin, Clock, Box, Wallet } from 'lucide-react';
import { getBitcoinPrice, getBlockHeight, getNetworkFees } from '../services/priceService';

const BTCTicker: React.FC = () => {
  const [price, setPrice] = useState<number | null>(null);
  const [time, setTime] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [blockHeight, setBlockHeight] = useState<number | null>(null);
  const [fees, setFees] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [priceData, heightData, feesData] = await Promise.all([
          getBitcoinPrice(),
          getBlockHeight(),
          getNetworkFees()
        ]);

        setPrice(priceData);
        setBlockHeight(heightData);
        setFees(feesData);
        setLoading(false);
      } catch (error) {
        console.warn('Failed to fetch data:', error);
        setLoading(false);
      }
    };

    fetchData();
    const dataInterval = setInterval(fetchData, 60000); // Update every minute

    const timeInterval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(dataInterval);
      clearInterval(timeInterval);
    };
  }, []);

  return (
    <div className="flex items-center gap-4 text-sm flex-wrap">
      <div className="flex items-center gap-2">
        <Bitcoin size={16} className="text-green-400" />
        <span className="terminal-text">
          {loading ? 'Loading...' : price ? `$${price.toLocaleString()}` : 'Price unavailable'}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <Box size={16} className="text-green-400" />
        <span className="terminal-text">
          {loading ? 'Loading...' : blockHeight ? `Block ${blockHeight.toLocaleString()}` : 'Height unavailable'}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <Wallet size={16} className="text-green-400" />
        <span className="terminal-text">
          {loading ? 'Loading...' : fees ? `${fees} sat/vB` : 'Fees unavailable'}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <Clock size={16} className="text-green-400" />
        <span className="terminal-text font-mono">
          {time.toLocaleTimeString()}
        </span>
      </div>
    </div>
  );
};

export default BTCTicker;