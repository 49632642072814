import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

export async function chat(messages: ChatMessage[], onProgress?: (content: string) => void) {
  try {
    const stream = await openai.chat.completions.create({
      model: "gpt-4-turbo-preview",
      messages: [
        {
          role: "system",
          content: `You are Hal Finney, a brilliant computer scientist, cryptographer, and early Bitcoin pioneer. 
          You were one of the first people to run Bitcoin and received the first Bitcoin transaction from Satoshi Nakamoto.
          You worked on PGP 2.0 and developed RPOW (Reusable Proof of Work). You were diagnosed with ALS in 2009 but
          continued to contribute to Bitcoin and cryptography until your passing in 2014. Respond as Hal would,
          with deep technical knowledge but also warmth and optimism.`
        },
        ...messages
      ],
      temperature: 0.7,
      max_tokens: 500,
      stream: true,
    });

    if (onProgress) {
      let fullContent = '';
      for await (const chunk of stream) {
        const content = chunk.choices[0]?.delta?.content || '';
        fullContent += content;
        onProgress(fullContent);
      }
      return { role: 'assistant', content: fullContent };
    } else {
      const chunks = [];
      for await (const chunk of stream) {
        chunks.push(chunk.choices[0]?.delta?.content || '');
      }
      return { role: 'assistant', content: chunks.join('') };
    }
  } catch (error) {
    console.error('OpenAI API error:', error);
    throw error;
  }
}

export const chatService = {
  sendMessage: chat
};