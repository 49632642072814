import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AchievementsPreview from './achievements/AchievementsPreview';

const Terminal = () => {
  const [input, setInput] = useState('');
  const [showAchievements, setShowAchievements] = useState(false);
  const [output, setOutput] = useState<string[]>([
    '╔════════════════════════════════════════════╗',
    '║        HAL FINNEY MEMORIAL TERMINAL        ║',
    '╚════════════════════════════════════════════╝',
    '',
    'System initialized: January 11, 2009 - Running Bitcoin',
    'Terminal ready. Type "help" for available commands.',
    '',
    '[HISTORICAL LOG]',
    '> First Bitcoin transaction received from Satoshi',
    '> Amount: 10 BTC',
    '> Block: 170',
    '',
  ]);
  const navigate = useNavigate();
  const terminalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [output]);

  const commands = {
    help: () => [
      '╔═══════════════════════════════════════╗',
      '║           Available Commands           ║',
      '╚═══════════════════════════════════════╝',
      '',
      'about       - Learn about Hal Finney',
      'chat        - Start AI conversation',
      'archives    - View Satoshi emails',
      'btc         - View Bitcoin transactions',
      'challenges  - Access crypto challenges',
      'resources   - View external resources',
      'museum      - Visit digital museum',
      'timeline    - View contribution timeline',
      'achievements- View available achievements',
      'clear       - Clear terminal',
      'quote       - Random Hal quote',
      'pgp         - View PGP public key',
      '',
    ],
    achievements: () => {
      setShowAchievements(true);
      return ['Loading achievements...'];
    },
    // ... rest of the commands remain the same
  };

  const handleCommand = (cmd: string) => {
    const trimmedCmd = cmd.trim().toLowerCase();
    if (trimmedCmd in commands) {
      return commands[trimmedCmd as keyof typeof commands]();
    }
    return [`Command not found: ${cmd}. Type "help" for available commands.`];
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newOutput = [...output, `> ${input}`, ...handleCommand(input)];
    setOutput(newOutput);
    setInput('');
  };

  return (
    <div className="space-y-6">
      <div className="terminal-window h-[calc(100vh-12rem)] overflow-y-auto p-4 rounded-lg font-mono text-green-400" ref={terminalRef}>
        <div className="space-y-2">
          {output.map((line, i) => (
            <div key={i} className="whitespace-pre-wrap terminal-text">{line}</div>
          ))}
        </div>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="flex items-center">
            <span className="mr-2 terminal-text">&gt;</span>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-1 bg-transparent outline-none terminal-text"
              autoFocus
            />
          </div>
        </form>
      </div>

      {showAchievements && (
        <AchievementsPreview />
      )}
    </div>
  );
};

export default Terminal;